import ReactDOM from 'react-dom';
import React from 'react';
import 'styles/global.scss';
import Error from 'pages/error/Error';

ReactDOM.render(
  <React.StrictMode>
    <Error />
  </React.StrictMode>,
  document.getElementById('root')
);
