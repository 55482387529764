import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Card from '../../common/components/card/Card';
import CardHeader from 'common/components/card/CardHeader';

const ERROR_PATH_TITLES = [{ path: '/', title: 'เกิดข้อผิดพลาด' }];

const ErrorRoute: React.FC = () => {
  return (
    <Card>
      <CardHeader paths={ERROR_PATH_TITLES} />
      <Switch>
        <Route exact path="/">
          <p className="font-semibold mb-2">กรุณาปิดแล้วลองใหม่อีกครั้ง</p>
        </Route>
      </Switch>
    </Card>
  );
};

export default ErrorRoute;
