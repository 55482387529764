import React from 'react';
import { HashRouter } from 'react-router-dom';
import ErrorRoute from 'pages/error/ErrorRoute';

const Error: React.FC = () => {
  return (
    <div className="Page">
      <HashRouter>
        <ErrorRoute />
      </HashRouter>
    </div>
  );
};

export default Error;
